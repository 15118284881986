@use "../abstracts/mixins";
@use "../grid/mixins/breakpoints";
@use "../grid/mixins/grid";

.ecosystem {
  &-hero {
    width: 100%;
    height: 50vh;

    @include breakpoints.media-breakpoint-up(md) {
      height: 75vh;
    }

    @include breakpoints.media-breakpoint-up(lg) {
      height: 100vh;
    }

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &-intro {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;

    @include breakpoints.media-breakpoint-up(lg) {
      margin-top: 7.5rem;
      margin-bottom: 7.5rem;
    }

    &-text {
      @include breakpoints.media-breakpoint-up(lg) {
        @include grid.make-col(8);
        @include grid.make-col-offset(2);
      }

      @include breakpoints.media-breakpoint-up(xl) {
        @include grid.make-col(6);
        @include grid.make-col-offset(3);
      }
    }
  }

  &-team {
    align-items: flex-end;

    &-title {
      margin-bottom: 2rem;

      @include breakpoints.media-breakpoint-up(md) {
        margin-bottom: 1rem;
      }
    }
  }

  &-admin {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  &-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 0 1rem 1.5rem 1rem;
    background-color: var(--bg-lightgreen);

    &-wrapper {
      height: 325px;
      margin-top: 2.5rem;

      @include breakpoints.media-breakpoint-up(md) {
        @include grid.make-col(6);
        height: 325px;
        margin-top: 3.5rem;
      }

      @include breakpoints.media-breakpoint-up(lg) {
        @include grid.make-col(4);
        height: 285px;
      }

      @include breakpoints.media-breakpoint-up(xl) {
        @include grid.make-col(3);
        height: 252.5px;
      }

      @include breakpoints.media-breakpoint-up(xxl) {
        height: 297.5px;
      }

      &:nth-child(odd) {
        & .ecosystem-card {
          background-color: var(--bg-lightblue);
        }
      }

      &:nth-child(4n) {
        & .ecosystem-card {
          background-color: var(--bg-lightred);
        }
      }
    }

    &-header {
      transform: translateY(-30px);

      &-image {
        width: 120px;
        height: 120px;
        margin-bottom: 0.5rem;
        object-fit: cover;
        border-radius: 50%;
      }

      &-role {
        margin: 0;
      }
    }
  }

  &-collapse {
    width: 100%;
    height: 400px;
    background-color: var(--primary-grey3);
  }

  &-jobs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 2.5rem;
    border: 1px solid var(--primary-grey4);
    text-align: center;

    &-wrapper {
      margin-top: 2rem;
      height: 325px;

      @include breakpoints.media-breakpoint-up(lg) {
        @include grid.make-col(8);
        height: 285px;
      }

      @include breakpoints.media-breakpoint-up(xl) {
        @include grid.make-col(6);
        height: 252.5px;
      }

      @include breakpoints.media-breakpoint-up(xxl) {
        height: 297.5px;
      }
    }

    & p {
      margin-top: 0.5rem;
    }
  }

  &-partners {
    padding-top: 2.5rem;
    padding-bottom: 2rem;

    @include breakpoints.media-breakpoint-up(lg) {
      padding-top: 6rem;
      padding-bottom: 5rem;
    }

    & h2 {
      text-align: center;
      margin-bottom: 2.5rem;
    }
  }
}

.partners-gallery {
  background-color: var(--primary-white);

  &-wrapper {
    position: relative;
    margin-top: 1.5rem;
    margin-bottom: 4.5rem;
    z-index: 1;
  }

  &-image {
    padding: 1rem;
  }

  &-title {
    text-align: center;
  }

  &-decoration {
    position: absolute;
    top: -75px;
    right: 25px;
    z-index: -1;

    @include breakpoints.media-breakpoint-up(sm) {
      top: -100px;
      right: 75px;
    }
  
    & svg {
      height: 100px;

      @include breakpoints.media-breakpoint-up(sm) {
        height: 150px;
      }
    }
  }

  & .splide__pagination {
    position: relative;
    top: -20px;

    & .splide__pagination__page {
      width: 9px;
      height: 9px;
      margin: 0 4px;
      background-color: var(--primary-grey4);
      border-radius: 50%;

      &.is-active {
        background-color: var(--primary-grey3);
      }
    }
  }
}