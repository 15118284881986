@use "../abstracts/mixins";
@use "../grid/mixins/breakpoints";
@use "../grid/mixins/grid";

.card-solutions {
  padding-left: 0;
  padding-right: 0;
}

%card-solution {
  @include grid.make-col-ready();
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  & p {
    margin-top: 12px;
    margin-bottom: 1.5rem;
  }
}

/* Card Solution Large */
.card-solution {
  @extend %card-solution;
  background-color: var(--primary-white);
  padding: 1rem;

  @include breakpoints.media-breakpoint-up(sm) {
    padding: 1.5rem;
  }

  @include breakpoints.media-breakpoint-up(lg) {
    max-height: 350px;
  }

  &-title {
    @include mixins.globalFontStyle('solution');
    margin: 1rem 0;
    color: var(--primary-green);
    text-transform: uppercase;
  }

  &-image {
    width: 100%;
  }

  & .btn {
    display: inline-block;
    position: relative;
    z-index: 10;
  }

  // Don't apply margin on last element
  &:not(:last-of-type) {
    margin-bottom: 1.5rem;
  }

  // Size exception for first illustration
  &.relais-solidaire .card-solution-image svg {
    height: auto;

    @include breakpoints.media-breakpoint-down(lg) {
      max-height: 300px;
    }

    @include breakpoints.media-breakpoint-up(lg) {
      height: 275px;
      transform: translateX(-125px);
    }

    @include breakpoints.media-breakpoint-up(xl) {
      height: 300px;
      transform: translateX(-100px);
    }

    @include breakpoints.media-breakpoint-up(xxl) {
      height: 325px;
      transform: translateX(-75px);
    }
  }

  // Size exception for second illustration
  &.evenementiel .card-solution-image {
    @include breakpoints.media-breakpoint-up(lg) {
      transform: translateX(15px) translateY(-15px);
    }

    @include breakpoints.media-breakpoint-up(xl) {
      width: 38%;
    }

    & svg {
      height: auto;

      @include breakpoints.media-breakpoint-down(lg) {
        max-height: 300px;
      }

      @include breakpoints.media-breakpoint-up(lg) {
        height: 350px;
      }

      @include breakpoints.media-breakpoint-up(xl) {
        height: 350px;
      }
    }
  }

  // Size exception for third illustration
  &.conseil-formations .card-solution-image svg {
    height: auto;

    @include breakpoints.media-breakpoint-down(lg) {
      max-height: 300px;
    }

    @include breakpoints.media-breakpoint-up(lg) {
      height: 300px;
      transform: translateX(-75px);
    }

    @include breakpoints.media-breakpoint-up(xl) {
      transform: translateX(-25px);
    }
  }

  // Size exception for fourth illustration
  &.essaimage .card-solution-image svg {
    height: auto;
    transform: translateX(0);

    @include breakpoints.media-breakpoint-down(lg) {
      max-height: 300px;
    }

    @include breakpoints.media-breakpoint-up(lg) {
      height: 300px;
      transform: translateX(25px);
    }

    @include breakpoints.media-breakpoint-up(xl) {
      height: 325px;
    }
  }

  @include breakpoints.media-breakpoint-up(lg) {
    @include grid.make-col(10);
    @include grid.make-col-offset(1);
    flex-direction: row;
    padding: 1.5rem 2.5rem;

    &-image {
      width: 50%;

      &:nth-child(odd) {
        margin-right: 1.5rem;
      }
    }
  
    &-content {
      width: 50%;
    }
    
    // Even cards images should be on the right side of the card
    &:nth-child(even) .card-solution-image {
      order: 12;
    }

    &:not(:last-of-type) {
      margin-bottom: 2.5rem;
    }
  }

  &-subtitle {
    margin-bottom: 1.5rem;
  }
}

/* Card Solution Small */
.card-solution-small-wrapper {
  @extend %card-solution;

  &:not(:last-of-type) {
    margin-bottom: 2.5rem;
  }

  @include breakpoints.media-breakpoint-up(xl) {
    @include grid.make-col(4);
    
    &:not(:last-of-type) {
      margin-bottom: 0;
    }
  }
}

.card-solution-small {
  width: 100%;
  height: 100%;
  padding: 1.5rem;
  background-color: var(--primary-white);

  &-image, 
  &-image svg {
    max-height: 200px;
  }

  &-image {
    position: relative;
    top: -50px;
    text-align: center;
  }

  &-content {
    display: flex;
    flex-direction: column;

    & .btn {
      max-width: fit-content;
    }
  }

  &-title {
    @include mixins.globalFontStyle('solution');
    margin: 0;
    color: var(--primary-green);
    text-transform: uppercase;
  }

  &-subtitle {
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
  }
}