@use "../abstracts/mixins";

.banner {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: rgba(253, 234, 222, 1);
  z-index: 1000;

  &-title {
    font-size: 18px;
    font-weight: 700;
    line-height: 1;
  }

  &-text {
    margin-top: 0.25rem;
    margin-bottom: 0;
    @include mixins.globalFontStyle("caption");
  }

  &-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    
    & svg {
      width: 20px;
      height: 20px;

      & > g > * {
        fill: var(--primary-black);
      }
    }
  }
}