@use "../grid/mixins/breakpoints";

.header-menu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: var(--header-menu-height);
  background-color: var(--primary-white);
  z-index: 1000;

  @include breakpoints.media-breakpoint-down(lg) {
    height: var(--header-menu-height-mobile);
  }

  &-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }

  &-navlist {
    display: flex;
    align-items: center;

    @include breakpoints.media-breakpoint-down(lg) {
      display: none;
    }

    &-item {
      position: relative;
      margin: 0 0.75rem;
      font-size: 18px;
      font-weight: 500;

      &:last-child {
        margin-right: 0;
      }
    }

    // Chevron for sub-menu
    &-link {
      color: inherit;
      cursor: pointer;
      transition: color .25s var(--cubic-bezier);

      &:hover,
      &.active {
        color: var(--primary-darkgreen);
      }

      &:hover,
      &.active {
        & .chevron-down * g {
          fill: var(--primary-darkgreen);
        }
      }

      & .chevron-down {
        vertical-align: bottom;
        transition: transform .15s var(--cubic-bezier);

        & * g {
          transition: fill .25s var(--cubic-bezier);
        }
      }
    }
  }

  &-separator {
    display: block;
    width: 100%;
    height: 1px;
    background-color: var(--primary-grey4);

    @include breakpoints.media-breakpoint-up(lg) {
      display: none;
    }
  }

  // Brand Logo
  &-brandlogo {
    width: 115px;
    height: 55px;
  }

  // Burger Menu
  &-toggle {
    width: 40px;
    height: 30px;
    cursor: pointer;

    @include breakpoints.media-breakpoint-up(lg) {
      display: none;
    }

    & .header-menu-open-icon,
    & .header-menu-close-icon  {
      display: none;

      &.active {
        display: block;
      }
    }
  }
}

.dropdown button {
  // Open state
  &.open {
    color: var(--primary-green);

    // Rotate the arrow
    & .chevron-down {
      transform: rotate(180deg);

      & * g {
        fill: var(--primary-green);
      }
    }

    // Display the Dropdown Menu
    & + .dropdown-menu {
    display: block;
    }
  }

  // Focus state
  &:focus {
    color: var(--primary-green);

    & .chevron-down * g {
        fill: var(--primary-green);
    }
  }
}

// Dropdown Menu
ul.dropdown-menu {
  display: none;
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  min-width: 12rem;
  background-color: var(--primary-white);
  border-radius: 4px;
  box-shadow: 0px 0px 10px 5px #e3e3e394;
  z-index: 1000;

  // Dropdown Item
  & li a {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 1rem;

    & svg {
      width: 40px;
      margin-right: 1rem;
    }

    &:hover {
      background-color: var(--bg-lightgrey);
    }
  }

  & .dropdown-divider {
    display: block;
    height: 1px;
    margin: 0 0.75rem;
    background-color: var(--primary-grey4);
  }
}