@use "../abstracts/mixins";
@use "../grid/mixins/breakpoints";
@use "../grid/mixins/grid";

.footer-menu {
  padding-top: 1.5rem;
  border-top: 1px solid var(--primary-grey4);
  background-color: var(--primary-white);

  &-infos {
    display: flex;
    align-items: center;

    /* Brand Logo */
    &-logo {
      @include grid.make-col-ready();
      margin-bottom: 1rem;

      @include breakpoints.media-breakpoint-up(lg) {
        @include grid.make-col(3);
        margin-bottom: 0;
      }

      & svg {
        width: 150px;
        max-height: auto;

        @include breakpoints.media-breakpoint-up(lg) {
          max-height: 300px;
          width: 100%;
        }
      }
    }

    /* Newsletter & Contact Wrapper */
    &-wrapper {
      @include grid.make-col-ready();
      display: flex;
      flex-direction: column;

      @include breakpoints.media-breakpoint-up(lg) {
        @include grid.make-col(9);
        flex-direction: row;
      }
    }

    &-newsletter,
    &-contact {
      width: 100%;

      @include breakpoints.media-breakpoint-up(lg) {
        width: 50%;
      }
    }

    /* Newsletter */
    &-newsletter {
      & p {
        margin-top: 0.5rem;
        margin-bottom: 1rem;
      }
    }

    /* Contact */
    &-contact {
      display: flex;
      flex-direction: column;
      margin-top: 2rem;


      @include breakpoints.media-breakpoint-up(lg) {
        align-items: flex-end;
        margin-top: 0;
      }

      &-list {
        margin-top: 1rem !important;

        @include breakpoints.media-breakpoint-up(lg) {
          text-align: right;
          margin-top: 2rem !important;
        }

        & > li {
          margin-bottom: 0.5rem;

          a {
            display: inline-block;
            border-bottom: 1px solid var(--primary-black);
          }
        }

        & address {
          font-style: normal;
        }
      }
    }
  }

  /* 
   * Sitemap 
  */
  &-sitemap {
    @include grid.make-col-ready();
    margin-top: 1.5rem;
    padding-top: 1.5rem;
    padding-bottom: 2.5rem;
    border-top: 1px solid var(--primary-grey4);

    @include breakpoints.media-breakpoint-up(lg) {
      @include grid.make-col(9);
      @include grid.make-col-offset(3);
      margin-top: 0;
    }

    &-list {
      width: 50%;

      @include breakpoints.media-breakpoint-up(md) {
        width: 25%;
      }

      &-item {
        @include mixins.globalFontStyle('caption');
        margin: 0.5rem 0;

        &:first-of-type {
          margin-top: 0;
        }

        & a {
          transition: color .25s var(--cubic-bezier);

          &:hover,
          &.active {
            color: var(--primary-darkgreen);
          }
        }
      }

      &-category {
        @include mixins.globalFontStyle('tag');
        text-transform: uppercase;
        color: var(--primary-grey1);
        margin-top: 1rem;

        &:first-of-type {
          margin-top: 0;
        }
      }

      &:nth-child(3) .footer-menu-sitemap-list-category {
        @include breakpoints.media-breakpoint-down(md) {
          margin-top: 0.5rem;
        }
      }
    }
  }

  /* 
   * Credits
  */
  &-credits {
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    padding: 0.5rem;
    min-height: 50px;
    background-color: var(--bg-lightgrey);

    &-list {
      display: flex;
      align-items: center;
      height: 100%;
      justify-content: flex-end;

      @include breakpoints.media-breakpoint-down(md) {
        flex-direction: column;
        align-items: flex-end;
      }

      & li {
        margin-right: 1rem;
        font-size: 13px;
        line-height: 20px;
        color: var(--primary-darkgreen);

        &:last-child {
          margin-right: 0;
        }

        @include breakpoints.media-breakpoint-down(md) {
          margin-right: 0;
        }
      }

      & a {
        font-weight: 700;
      }
    }
  }
}

.social-medias-email-item {
  display: flex;
  align-items: center;

  @include breakpoints.media-breakpoint-up(lg) {
    justify-content: flex-end;
  }

  & svg {
    margin-right: 0.75rem;
  }

  & button {
    border-bottom: 1px solid var(--primary-black);
  }
}