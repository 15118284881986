/* Global variables */
:root {
  // Colors
  --primary-black: #030F1C;
  --primary-white: #FFF;
  --primary-grey1: #7C7C7C;
  --primary-grey2: #A5A5A5;
  --primary-grey3: #D1D1D1;
  --primary-grey4: #E3E3E3;
  --primary-orange: #F39659;
  --primary-yellow: #F1C400;
  --primary-red: #FE5000;
  --primary-green: #33B084;
  --primary-lightgreen: #71CC98;
  --primary-darkgreen: #006F62;
  --primary-blue: #009CBD;
  --primary-lightblue: #74D1EA;
  --primary-darkblue: #1E22AA;
  --bg-lightgrey: #F6F6FB;
  --bg-lightgreen: rgba(113, 204, 152, 0.2);
  --bg-lightblue: rgba(116, 209, 234, 0.2);
  --bg-lightyellow: rgba(241, 196, 0, 0.15);
  --bg-lightred: rgba(243, 150, 89, 0.2);
	// Fonts
  --font-family: 'BrandonGrotesque', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  // Motion
  --cubic-bezier: cubic-bezier(0.645, 0.045, 0.355, 1);
  // Sizing
  --header-menu-height: 100px;
  --header-menu-height-mobile: 80px;
}