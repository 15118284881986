/* Define a font path */
$font_path: '../fonts/BrandonGrotesque/';
$font_name: 'BrandonGrotesque';

/* Regular */
@font-face {
	font-family: $font_name;
	font-display: swap;
	src: url($font_path + $font_name + '-Regular.woff2') format('woff2'),
       url($font_path + $font_name + '-Regular.woff') format('woff');
  font-weight: 400;
	font-style: normal;
}

/* Light */
@font-face {
	font-family: $font_name;
	font-display: swap;
	src: url($font_path + $font_name + '-Light.woff2') format('woff2'),
       url($font_path + $font_name + '-Light.woff') format('woff');
  font-weight: 300;
	font-style: normal;
}

/* Medium */
@font-face {
	font-family: $font_name;
	font-display: swap;
	src: url($font_path + $font_name + '-Medium.woff2') format('woff2'),
       url($font_path + $font_name + '-Medium.woff') format('woff');
  font-weight: 500;
	font-style: normal;
}

/* Bold */
@font-face {
	font-family: $font_name;
	font-display: swap;
	src: url($font_path + $font_name + '-Bold.woff2') format('woff2'),
       url($font_path + $font_name + '-Bold.woff') format('woff');
  font-weight: 700;
	font-style: normal;
}

/* Black */
@font-face {
	font-family: $font_name;
	font-display: swap;
	src: url($font_path + $font_name + '-Black.woff2') format('woff2'),
       url($font_path + $font_name + '-Black.woff') format('woff');
  font-weight: 900;
	font-style: normal;
}